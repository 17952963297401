<template>
  <BaseModalSmall
      v-show="show"
      v-bind:isValidated="true"
      @confirm="confirm(true)"
      @close="$emit('close')"
      @cancel="confirm(false)"
      :noSlide="true"
      :splitButtons="true"
      confirmText="Yes, Share"
      decline-text="No Thank You"
      v-if="broker"
  >
    <template v-slot:header><img :src="broker.logo" width="50" class="mr-auto">{{ broker.company_name }}</template>
    <template v-slot:body>
      <p>
        Are you happy for this contact information to be shared with {{ broker.company_name }}? This can be useful as
        they may be able to provide additional information to those named by phone or email.
      </p>
    </template>
    <template v-slot:button>
      Button here
    </template>
  </BaseModalSmall>
</template>

<script>
import BaseModalSmall from '@/common/ui/BaseModalSmall'

export default {
  name: 'BrokerShareModal',
  components: { BaseModalSmall },
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {}
  },
  computed: {
    broker () {
      return this.$store.getters.checkoutBasket?.coupon?.broker
    }
  },
  methods: {
    confirm (value) {
      this.$emit('confirm', value)
    }
  }
}
</script>