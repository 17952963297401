<template>
  <NavQuestion
      v-bind:heading="heading"
      v-bind:background="'bg-share'"
      v-bind:number="questionNumber"
      v-bind:questionOptions="false"
      v-bind:shareOptions="true"
  >
    <template v-slot:contents>
      <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
        <div style="padding: 20px">
          It is good practice to notify your
          <span v-if="guardians">Executors, Trustees &amp; Guardians</span>
          <span v-else>Executors &amp; Trustees</span>
          of their appointment. If you add their contact details we will send
          them details of their role by email / SMS (the specific content of
          your Will is not shared and contact details will not be used for
          marketing purposes).
        </div>
        <div class="notification pb30">
          If you do not want to notify your executors, trustees and guardians of
          their responsibilities then deselect them individually here.
        </div>
        <div class="share ipad landscape">
          <div class="screen">
            <img
                alt="email"
                src="https://mylastwill.s3.amazonaws.com/static/img/share-screenshot.png"
            />
          </div>
          <div class="button"></div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
        <div class="row mt30">
          <div
              v-for="(person, index) in sharePeople"
              v-bind:key="index"
              class="col-lg-6 col-md-4 col-sm-6 col-xs-12"
          >
            <div
                v-bind:class="{ selected: person.share }"
                class="input-option input-option-share"
                v-on:click="select(person)"
            >
              <div class="tick-wrapper"></div>
              <span class="tick ticked"></span>
              <div class="image-wrapper">
                <img alt="male" v-bind:src="genderPicture(person)"/>
              </div>
              <div class="person-details">
                <p>{{ person.full_name }}</p>
                <div class="contact-details">
                  <div class="input-container">
                    <input
                        @click.stop
                        @input="inputPhone(person, $event)"
                        :value="person.phone"
                        v-bind:required="person.share"
                        autocomplete="false"
                        class="input-standard-grey validation"
                        max="15"
                        maxlength="15"
                        name="email"
                        placeholder="Phone number"
                        type="text"
                    />
                  </div>
                  <div class="input-container">
                    <input
                        @click.stop
                        @input="inputEmail(person, $event)"
                        :value="person.email"
                        v-bind:required="person.share"
                        autocomplete="false"
                        class="input-standard-grey validation"
                        max="15"
                        maxlength="60"
                        name="phone"
                        placeholder="Email address"
                        type="email"
                    />
                    <ErrorPop
                        v-show="
                        person.share &&
                        !person.email &&
                        !person.phone &&
                        showErrors
                      "
                        :message="'Phone or email required'"
                        v-bind:displayClose="false"
                    ></ErrorPop>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BrokerShareModal
            :show="show.brokerSharePrompt"
            @confirm="confirmedBrokerShare($event)"
            @close="show.brokerSharePrompt=false"
        />
      </div>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
          v-bind:validated="true"
          v-bind:forwardText="'Next'"
          v-bind:backLink="'/advice'"
          v-bind:forwardLink="null"
          v-bind:forwardDisabled="false"
          @forwardClick="prompt()"
          :externalForwardUrl="null"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import NavQuestion from '@/common/ui/NavQuestion'
import ErrorPop from '@/common/ui/ErrorPop'
import BrokerShareModal from '@/views/pages/CheckoutView/SharePage/BrokerShareModal'

export default {
  name: 'SharePage',
  components: {
    BrokerShareModal,
    NavQuestionButtons,
    NavQuestion,
    ErrorPop
  },
  created () {
    console.log('created', this.sharePeople)
    if (!this.sharePeople.length) {
      this.$router.push('/draft_will').catch((error) => {
        console.log(error)
      })
    } else {
      this.$store.dispatch('sharePeopleSetYes')
    }
  },
  computed: {
    sharePeople () {
      return this.$store.getters.sharePeople
    },
    peopleShared () {
      // people who are selected to be shared about
      return this.sharePeople.filter(person => person.share)
    },
    guardians () {
      var guaridans = this.sharePeople.filter(
          (person) =>
              person.guardian_primary ||
              person.guardian_sole_primary ||
              person.guardian_secondary ||
              person.guardian_backup
      )
      if (guaridans.length > 0) {
        return true
      } else {
        return false
      }
    },
    questionNumber () {
      if (this.$store.getters.checkoutBasket.active_subscription) return '5'
      if (this.mandate) return '8'
      return '5'
    },
    mandate () {
      return this.$store.getters.directDebitMandate
    },
    heading () {
      if (this.guardians) {
        return 'Notifying your Executors, Trustees & Guardians'
      }
      return 'Notifying your Executors & Trustees '
    }
  },
  watch: {},
  beforeRouteLeave (to, from, next) {
    if (this.validatePage()) {
      this.saveShareStatus()
      next()
    } else {
      next(false)
    }
  },
  mounted () {
    this.$store.commit('checkoutProgress', {
      advice: true
    })
  },
  data () {
    return {
      validated: false,
      showErrors: false,
      people: null,
      show: {
        brokerSharePrompt: false
      }
    }
  },
  methods: {
    prompt () {
      console.log('prompt')

      if (!this.validatePage()) return
      this.saveShareStatus()

      if (this.$store.getters.checkoutBasket?.coupon?.broker && this.peopleShared.length) {
        this.show.brokerSharePrompt = true
      } else {
       this.gotoNextPage()
      }
    },
    gotoNextPage() {
      this.$router.push({ name: 'DraftWillPage' }).catch((error) => {
        console.log(error)
      })
    },
    async confirmedBrokerShare (value) {
      console.log('confirm share status', value)
      // close broker modal
      this.show.brokerSharePrompt = false
      // set broker share status on each shared person to prompt response
      for (let i = 0; i < this.peopleShared.length; i++) {
        await this.selectBrokerShare(this.peopleShared[i], value)
      }
      // save all people again
      this.saveShareStatus()
      // proceed to next page
      this.gotoNextPage()
    },
    validatePerson (person) {
      if (person.phone || person.email) {
        person.share = true
      }
    },
    genderPicture (person) {
      if (person.gender === 'Male') {
        return 'https://mylastwill.s3.amazonaws.com/static/img/button-male.png'
      }
      return 'https://mylastwill.s3.amazonaws.com/static/img/button-female.png'
    },
    inputPhone (person, event) {
      var personCopy = Object.assign({}, person)
      personCopy.phone = event.target.value
      this.$store.commit('personAdd', personCopy)
    },
    inputEmail (person, event) {
      var personCopy = Object.assign({}, person)
      personCopy.email = event.target.value
      this.$store.commit('personAdd', personCopy)
    },
    select (person) {
      var personCopy = Object.assign({}, person)
      personCopy.share = !personCopy.share
      this.$store.commit('personAdd', personCopy)
    },
    async selectBrokerShare (person, value) {
      var personCopy = Object.assign({}, person)
      personCopy.share_broker = value
      this.$store.commit('personAdd', personCopy)
    },
    saveShareStatus () {
      this.$store.dispatch('savePeople')
    },
    validatePage (person = null) {
      if (person) person.share = true
      var validated = true
      for (var i = 0; i < this.sharePeople.length; i++) {
        if (
            this.sharePeople[i].share === true &&
            (this.sharePeople[i].email === '' || !this.sharePeople[i].email) &&
            (this.sharePeople[i].phone === '' || !this.sharePeople[i].phone)
        ) {
          validated = false
        }
      }
      this.showErrors = true
      return validated
    }
  }
}
</script>

<style scoped>
.notification {
  padding: 20px;
  background-color: #f9f9f9;
  color: black;
  border-radius: 10px;
}
</style>
